@layer layout {
    .container {
        margin: 0;
        padding: 0;
    }
    .header,
    .footer,
    .slider {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        position: relative;
        text-align: center;
        padding: 0 2em;
    }
    .footer h3 {
        font-size: 2vw;
        letter-spacing: 0.1rem;
    }
}