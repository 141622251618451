@layer reset {
    *:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
        all: unset;
        display: revert;
    }
    *, *::before, *::after {
        box-sizing: inherit;
    }
    html {
        box-sizing: border-box;
        text-rendering: optimizeLegibility;
        -moz-text-size-adjust: none;
        -webkit-text-size-adjust: none;
        text-size-adjust: none;
    }
    body, h1, h2, h3, h4, p,
    figure, blockquote, dl, dd {margin-block-end: 0;}
    ul[role='list'],
    ol[role='list'] {list-style: none;}
    h1, h2, h3, h4,
    button, input, label {line-height: 1.1;}
    h1, h2,
    h3, h4 {text-wrap: balance;}
    a:not([class]) {
        text-decoration-skip-ink: auto;
        cursor: pointer;
    }
    a, button {cursor: revert;}
    img, picture {
        width: 100%;
        height: 100%;
        max-width: 100%;
        vertical-align: middle;
    }
    img {
        max-inline-size: 100%;
        max-block-size: 100%;
    }
    img[width] {width: auto;}
    img[width][height] {height: auto;}
    input, button,
    textarea, select {font: inherit;}
    input {opacity: 0;}
    button, select {
        line-height: 1.15;
        outline: none;
        overflow: visible;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    textarea:not([rows]) {min-height: 10em;}
    select {
        background-color: transparent;
        border: none;
        padding: 0 1em 0 0;
        margin: 0;
        width: 100%;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        &::-ms-expand {display: none;}
    }
    :target {scroll-margin-block: 0;}
    code {font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;}
    @media (prefers-reduced-motion: reduce) {
        *,
        *::before,
        *::after {
            animation-duration: 0.01ms !important;
            animation-iteration-count: 1 !important;
            transition-duration: 0.01ms !important;
            scroll-behavior: auto !important;
        }
    }
}
